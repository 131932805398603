














































































































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { UserStoreModule } from '@/store/modules/user';
import CommonService from '@/service/common';
import MENUS from '@/json/menu.json';
import { CacheUtil } from '@/model/cache-model';
import ProjectService from '@/service/project';
import { ProjectEntityModel } from '@/entity-model/project-entity';
import PRESENTED_IMAGE_SIMPLE from '@jtl/ant-design-vue/lib/empty/simple';

@Component
export default class DashboardComponent extends BaseComponent {
    /**
     * 左侧菜单收起/展开
     */
    private collapsed: boolean = false;

    /**
     * 项目列表
     */
    private projectList: Array<ProjectEntityModel> = null;

    /**
     * 颜色主题
     */
    theme: string = 'dark';

    // 待处理的工单数量
    assignedNumber: number = 0;
    toClaimNumber: number = 0;

    created() {
        // 订阅工单数量更新
        this.$eventHub.$on('GET_WORKORDER_NUMBERS', () => {
            this.getNumbers();
        });

        // 订阅左侧菜单更新
        this.$eventHub.$on('MENU_RELOAD', () => {
            CommonService.getUserPermissions2(0);
        });

        this.initProject();
        // this.getNumbers();
        // setInterval(() => {
        //     this.getNumbers();
        // }, 120000);
    }

    /**
     * 获取空项目状态图标
     */
    get EmptySimpleImage() {
        return PRESENTED_IMAGE_SIMPLE;
    }

    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin(): boolean {
        return _.get(UserStoreModule.UserInfo, 'IsSuperAdmin');
    }

    /**
     * 是否是企业用户
     */
    get IsCorpAdmin(): boolean {
        return !UserStoreModule.CurrentProjectId;
    }

    get MenuTheme() {
        return this.theme === 'light' ? 'light' : 'dark';
    }

    /**
     * 获取登陆用户的菜单
     */
    get MenuList() {
        if (UserStoreModule.IsSuperAdmin) {
            // 超级管理员有所有菜单的权限
            return MENUS;
        }
        return _.filter(_.cloneDeep(MENUS), menu => {
            if (UserStoreModule.UserPermissions.indexOf(menu.route) === -1 && !JTL.debug) {
                return false;
            }
            menu.subMenus = _.filter(menu.subMenus, (subMenu: any) => UserStoreModule.UserPermissions.indexOf(subMenu.route) > -1 || JTL.debug);
            return true;
        });
    }

    /**
     * 待处理的工单数量
     */
    getNumbers() {
        CommonService.getMenuNumbers().then(res => {
            this.assignedNumber = res.assigned;
            this.toClaimNumber = res.toClaim;
        });
    }

    /**
     * 获取企业名称
     */
    get CorpName(): string {
        return UserStoreModule.CorpName;
    }
    get CurrentSelectProjectName(): string {
        return _.get(UserStoreModule.CurrentProject, 'name');
    }
    /**
     * 获取企业Logo
     */
    get Logo(): string {
        return UserStoreModule.Logo;
    }

    /**
     * 获取登录的用户名
     */
    get UserName() {
        return UserStoreModule.UserName;
    }

    /**
     * 默认打开的一级菜单项
     */
    get DefaultOpenMenuKey() {
        if (this.collapsed) {
            return [];
        }
        const openMenuKey = this.$route.meta.parentMenu || this.getParentMenu(this.$route.path);
        if (openMenuKey) {
            return [openMenuKey];
        }
        return [];
    }

    /**
     * 获取左侧菜单展示/收起状态的图标
     */
    get CollapsedIcon() {
        return this.collapsed ? 'menu-unfold' : 'menu-fold';
    }

    /**
     * 判断菜单是否被选中
     */
    isMenuSelected(menuRoute: string): boolean {
        return this.$route.meta.parentPath === menuRoute || this.$route.meta.parentMenu === menuRoute || this.$route.path === menuRoute || this.getParentMenu(this.$route.path) === menuRoute;
    }

    /**
     * 菜单点击事件
     */
    menuClick(menu: {name: string, route: string}) {
        this.$router.push(menu.route);
    }

    /**
     * 返回企业
     */
    async backToCorpClick() {
        UserStoreModule.SET_CURRENT_PROJECT(null);
        await CommonService.getUserPermissions2(0);
        this.$router.push('/dashboard/project-select');
    }

    /**
     * 退出登录
     */
    loginOutClick() {
        CacheUtil.cleanCache();
        UserStoreModule.SET_USERINFO();
        UserStoreModule.SET_REDIRECT_PATH();
        // UserStoreModule.SET_CURRENT_PROJECT(null);
        this.$router.push('/sign/login');
    }

    /**
     * 选择项目点击事件
     */
    async selectProjectClick(projectModel: ProjectEntityModel) {
        UserStoreModule.SET_CURRENT_PROJECT(projectModel);
        await CommonService.getUserPermissions2(0);
        this.$router.push('/dashboard/index');
    }

    /**
     * 初始化项目列表
     */
    initProject() {
        if (!this.IsSuperAdmin) {
            ProjectService.getAllProject().then(res => {
                this.projectList = res;
                if (this.projectList && this.projectList.length > 0) {
                    const currentProject = _.find(this.projectList, item => item.id === UserStoreModule.CurrentProjectId) || this.projectList[0];
                    UserStoreModule.SET_CURRENT_PROJECT(currentProject);
                } else {
                    UserStoreModule.SET_CURRENT_PROJECT(null);
                }
            });
        }
    }

    /**
     * 获取父级菜单的路由名
     */
    private getParentMenu(routePath: string) {
        if (!MENUS || MENUS.length === 0) {
            return;
        }
        let parentMenu: string = null;
        for (let i = 0; i < MENUS.length; i++) {
            if (MENUS[i].route === routePath) {
                return routePath;
            }
            if (MENUS[i].subMenus && MENUS[i].subMenus.length > 0) {
                for (let j = 0; j < MENUS[i].subMenus.length; j++) {
                    if (MENUS[i].subMenus[j].route === routePath) {
                        return MENUS[i].route;
                    }
                }
            }
        }
        return parentMenu;
    }
}

